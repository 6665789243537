import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/solid";

export default function AccordionInfo() {
  return (
    <div className="w-full rounded-2xl">
      <Disclosure defaultOpen>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex justify-between w-full px-4 py-2 font-medium text-left bg-neutral-100 dark:bg-neutral-700 dark:hover:bg-neutral-500 rounded-lg hover:bg-neutral-200 focus:outline-none focus-visible:ring focus-visible:ring-neutral-500 focus-visible:ring-opacity-75">
              <span>상세 설명</span>
              <ChevronUpIcon
                className={`${
                  open ? "transform rotate-180" : ""
                } w-5 h-5 text-neutral-500`}
              />
            </Disclosure.Button>
            <Disclosure.Panel
              className="px-4 pt-4 pb-2 text-neutral-500 text-sm dark:text-neutral-400"
              as="p"
            >
              인적자원관리는 인적자원관리 수업에서 만난 세 명의 학생들이 모여 만든 그룹입니다
              2022년 코로나 감소세와 함께 돌아온 한양가요제를 위한 프로젝트 음원이며, MZ세대로서 자신이 느껴온 것들을 가사에 담았습니다.
              밝고 명랑한 멜로디와 중독성 있는 훅으로 호응을 유도하는데 최적화된 음원입니다
              대학 축제의 분위기와 젊은 혈기로 가득찬 20대의 포효를 즐겨보시죠.
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <Disclosure defaultOpen as="div" className="mt-5 md:mt-8">
        {({ open }) => (
          <>
            <Disclosure.Button className="flex justify-between w-full px-4 py-2 font-medium text-left bg-neutral-100 dark:bg-neutral-700 dark:hover:bg-neutral-500 rounded-lg hover:bg-neutral-200 focus:outline-none focus-visible:ring focus-visible:ring-neutral-500 focus-visible:ring-opacity-75">
              <span>상세 사항</span>
              <ChevronUpIcon
                className={`${
                  open ? "transform rotate-180" : ""
                } w-5 h-5 text-neutral-500`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="px-4 pt-4 pb-2 flex flex-col text-xs text-neutral-500 dark:text-neutral-400 overflow-hidden">
              <span>2000 x 2000 px.IMAGE(685KB)</span>
              <br />
              <span>Contract Address</span>
              <span className="text-base text-neutral-900 dark:text-neutral-100 line-clamp-1">
                <a href="https://polygonscan.com/address/0x2953399124f0cbb46d2cbacd8a89cf0599974963">0x2953399124F0cBB46d2CbACD8A89cF0599974963</a>
              </span>

              <br />
              <span>OpenSea에서 확인하기</span>
              <span className="text-base text-neutral-900 dark:text-neutral-100">
              <a href="https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/14220527420517662924351548006782962277600571867307753507927354649603376939009">OpenSea에서 MZ 바로가기</a>
              </span>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
}
